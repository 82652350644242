import './App.scss';
import './assets/fonts/fonts.scss'
import { Outlet } from 'react-router-dom'

function App() {
  return (
    <div className="App">
      <div id="detail">
        <Outlet />
      </div>
    </div>
  );
}

export default App;
