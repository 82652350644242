import React, { useContext, useEffect } from 'react'
import './MainPage.scss'
import DelayLink from '../components/DelayLink'
import { FormattedMessage } from 'react-intl'
import { currentDictionary, Context } from '../components/LanguageWrapper'
import dotsDataRu from '../../assets/json/ru.json'
import dotsDataEn from '../../assets/json/en.json'
import useWindowDimensions from '../hooks/useWindowDimensions'

export default function MainPage() {

  const context = useContext(Context)
  const pageInfo = currentDictionary

  const { width, height } = useWindowDimensions()

  let dotsData = dotsDataRu
  if (context.locale === 'en') {
    dotsData = dotsDataEn
  }

  function hideDotsExcept(currentDotCode) {
    dotsData.items.forEach(function (it, index, array) {
      if (it.code.substring(0, 3) !== currentDotCode) {
        const el = document.getElementById(`dot-${it.code.substring(0, 3)}`)
        hideElement(el)
      }
    })
  }

  function hideElement(element) {
    element.classList.remove('element-hided')
    element.classList.remove('element-pulse')
    element.classList.remove('element-showing')
    element.classList.remove('element-hiding')
    void element.offsetWidth
    element.classList.add('element-hiding')
  }

  function countryAnimation(item) {
    const element = document.getElementById(`dot-${item.code.substring(0, 3)}`)
    hideDotsExcept(item.code.substring(0, 3))
    hideElement(document.getElementById(`dot-rus`))
    hideElement(document.getElementById(`main-content`))
    document.getElementById(`main-container`).classList.add('pe-none')
    dotsData.items.forEach((item) => {
      if (item.code !== 'chn2') {
        const dot = document.getElementById(`btn-${item.code.substring(0, 3)}`)
        const btn = document.getElementById(`btn-${item.code.substring(0, 3)}`)
        dot.classList.remove('dot__clickable')
        dot.classList.add('pe-none')
        btn.classList.remove('dot__clickable')
        btn.classList.add('pe-none')
      }
    })
    document.getElementById(`txt-${item.code}`).classList.add('pe-none')
    element.classList.remove('element-hided')
    element.classList.remove('element-pulse')
    element.classList.remove('element-showing')
    element.classList.remove('element-hiding')
    void element.offsetWidth;
    element.classList.add('element-pulse')
    element.classList.add('element-hided')
  }

  useEffect(() => {
    let element = document.getElementById(`main-content`)
    element.classList.remove('element-showing')
    void element.offsetWidth
    element.classList.add('element-showing')
  }, [context.locale])

  useEffect(() => {
    dotsData.items.forEach((it) => {
      // if (it.code !== 'chn2') {
      const dot = document.getElementById(`dot-${it.code.substring(0, 3)}`)
      const txt = document.getElementById(`txt-${it.code}`)
      // if (dot !== null) {
      dot.onmouseover = function (e) {
        txt.classList.add('text-light-hover')
      }
      dot.onmouseout = function (e) {
        txt.classList.remove('text-light-hover')
      }

      txt.onmouseover = function (e) {
        dot.classList.add('dot-hover')
      }
      txt.onmouseout = function (e) {
        dot.classList.remove('dot-hover')
      }
      // }

      // }
    })
  }, [dotsData.items])


  const isFirefox = typeof InstallTrigger !== 'undefined';
  let containerSize = {}
  let contentSize = {}
  let overContainerSize = {}
  if (!isFirefox) {
    const diagonalStantart = 1920 / 1080;
    const currentDiagonal = width / height;
    let zoomValue = (diagonalStantart > currentDiagonal) ? width / 1920 : height / 1080
    if (currentDiagonal < 1.35) {
      zoomValue = height / 1080
    }
    containerSize = {
      zoom: zoomValue
    }
    contentSize = {
      transform: `scale(${zoomValue}, ${zoomValue})`
    }
    overContainerSize = {
      width: `${zoomValue * 1920}px`,
      height: `${zoomValue * 1080}px`,
      maxWidth: `${zoomValue * 1920}px`,
      maxHeight: `${zoomValue * 1080}px`
    }
  }

  return (
    <div className='background-image background'>
      <div className='rotate-phone-bg' style={overContainerSize}>
        <div id='main-container' className='main-container' style={containerSize}>
          <div className='map-image map'></div>
          <div className='grid1-image grid__1'></div>
          <div className='grid2-image grid__2'></div>
          <div className='grid3-image grid__3'></div>
          <div className='grid4-image grid__4'></div>
          <div className='grid5-image grid__5'></div>
          <div className='grid6-image grid__6'></div>
          <div className='grid7-image grid__7'></div>
          <div className='grid8-image grid__8'></div>
          <div id='dots-container' className='dots-container'>
            <div id={`dot-rus`} className={`dot element-showing dot__rus`}></div>
            {dotsData.items.map((item, index) => (
              (item.code !== 'chn2' && item.code !== 'blr2' && item.code !== 'cub2' && item.code !== 'mmr2') &&
              <div key={index} id={`dot-${item.code.substring(0, 3)}`} className={`dot element-showing dot__${item.code.substring(0, 3)}`}>
                <DelayLink className={`dot__clickable`}
                  to={`pages/${item.code}`}
                  delay={800}
                  onDelayStart={() => {
                    countryAnimation(item)
                  }}
                  onDelayEnd={() => { }}
                >
                  <div key={index} id={`btn-${item.code.substring(0, 3)}`} className={`dot__clickable`}>
                  </div>
                </DelayLink>
              </div>
            ))}
          </div>
        </div>
        <div id='main-content' className='main-content element-showing' style={contentSize}>
          <div className='logo-image logo pe-none'></div>
          <div className='title user-select-none pe-none'>
            <FormattedMessage
              id="title"
              defaultMessage="Центры открытого образования"
            />
          </div>
          <div className='subtitle user-select-none pe-none'>
            <FormattedMessage
              id="subtitle"
              defaultMessage="47 стран"
            />
          </div>
          <ul className='column-list'>
            {pageInfo.items.sort(function (a, b) {
              var nameA = a.link_title.toLowerCase(), nameB = b.link_title.toLowerCase()
              if (nameA < nameB) //сортируем строки по возрастанию
                return -1
              if (nameA > nameB)
                return 1
              return 0 // Никакой сортировки
            }).map((item, index) => (
              <li key={index} id={`txt-${item.code}`} className='text-light'>
                <DelayLink
                  to={`pages/${item.code}`}
                  delay={800}
                  onDelayStart={() => {
                    countryAnimation(item)
                  }}
                  onDelayEnd={() => { }}
                >
                  {item.link_title}
                </DelayLink>
              </li>
            ))}
          </ul>
          <div className='phone-block'>
            <FormattedMessage
              id="phone"
              defaultMessage=""
            />
            8 (495) 911‑61‑11
          </div>
          <div className='language-selector'>
            <div
              id='ru'
              className={`language-button text-light language-button__first user-select-none ${context.locale !== 'ru' && 'language-button__unactive'}`}
              onClick={context.selectLanguage}>
              Русский
            </div>
            <div
              id='en'
              className={`language-button text-light user-select-none ${context.locale !== 'en' && 'language-button__unactive'}`}
              onClick={context.selectLanguage}>
              English
            </div>
          </div>
        </div>
      </div>
      <div className='rotate-phone-image rotate-phone' />
    </div>
  )
}
