import React, { useState } from 'react'
import { IntlProvider } from 'react-intl'
import Russian from '../../assets/json/ru.json'
import English from '../../assets/json/en.json'

export const Context = React.createContext()

const local = navigator.language.substring(0, 2).toLowerCase()
export let currentDictionary
if (local === 'en') {
    currentDictionary = English
} else {
    currentDictionary = Russian
}

const LanguageWrapper = (props) => {
    const [locale, setLocale] = useState(local)
    const [messages, setMessages] = useState(currentDictionary)

    function selectLanguage(e) {
        const newLocale = e.target.id
        setLocale(newLocale)
        if (newLocale === 'en') {
            currentDictionary = English
        } else {
            currentDictionary = Russian
        }
        setMessages(currentDictionary)

    }

    return (
        <Context.Provider value={{ locale, selectLanguage }}>
            <IntlProvider messages={messages} locale={locale}>
                {props.children}
            </IntlProvider>
        </Context.Provider>
    )
}
export default LanguageWrapper