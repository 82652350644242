import React, { useEffect, useState } from 'react'
import './CountryPage.scss'
import './OverrideLightbox.scss'
import { useParams } from 'react-router-dom'
import Lightbox from 'react-image-lightbox'
import "react-image-lightbox/style.css";
import DelayLink from '../components/DelayLink'
import { currentDictionary } from '../components/LanguageWrapper'
import useWindowDimensions from '../hooks/useWindowDimensions';

export default function CountryPage() {
  const { width, height } = useWindowDimensions()

  const pageInfo = currentDictionary

  let code = useParams()

  let currentCountryObj = pageInfo.items.find(obj => obj.code === code.id)
  console.log(currentCountryObj.title)

  const countryCode = code.id.substring(0, 3)
  console.log(countryCode)

  const flag_image = `/images/flags/4x3/${countryCode}.svg`

  const images = [
    `/images/photo/${code.id}/photo_1.jpg`,
    `/images/photo/${code.id}/photo_2.jpg`,
    `/images/photo/${code.id}/photo_3.jpg`
  ]
  // const images = [
  //   `/images/photo1/${code.id}.jpg`
  // ]

  const [photoIndex, setPhotoIndex] = useState(0);
  const [showModal, setShowModal] = useState(false);

  const openModalAndSetIndex = (index) => {
    setPhotoIndex(index);
    setShowModal(true);
    return;
  };

  const main_text = currentCountryObj.text.replaceAll('{0}', '<span class="bold">').replaceAll('{1}', '</span>')

  const pageShow = () => {
    const element = document.getElementById('page-bg')
    element.classList.remove('hided')
    element.classList.remove('page-showing')
    element.classList.remove('page-hiding')
    void element.offsetWidth;
    element.classList.add('page-showing')
    setTimeout(() => {
      document.getElementById('btn-back-area').classList.remove('pe-none')
    }, 800)
  }

  const pageHide = () => {
    document.getElementById('btn-back-area').classList.add('pe-none')
    const element = document.getElementById('page-bg')
    element.classList.remove('page-showing')
    element.classList.remove('page-hiding')
    void element.offsetWidth;
    element.classList.add('page-hiding')

    element.classList.add('hided')
  }

  useEffect(() => {
    pageShow()
  }, [])

  const isFirefox = typeof InstallTrigger !== 'undefined';
  let containerSize = {}
  let contentSize = {}
  let overContainerSize = {}
  if (!isFirefox) {
    const diagonalStantart = 1920 / 1080;
    const currentDiagonal = width / height;
    let zoomValue = (diagonalStantart > currentDiagonal) ? width / 1920 : height / 1080
    if (currentDiagonal < 1.35) {
      zoomValue = height / 1080
    }
    containerSize = {
      zoom: zoomValue
    }
    contentSize = {
      transform: `scale(${zoomValue}, ${zoomValue})`
    }
    overContainerSize = {
      width: `${zoomValue * 1920}px`,
      height: `${zoomValue * 1080}px`,
      maxWidth: `${zoomValue * 1920}px`,
      maxHeight: `${zoomValue * 1080}px`
    }
  }

  const isBigCountryObjList = currentCountryObj.organizations.length > 4
  const variativeUlStyle = isBigCountryObjList ? { columnCount: 2 } : {}
  const variativeLiStyle = isBigCountryObjList ? { width: `492px` } : { width: `892px` }

  return (
    <div className='background-image background'>
      <div className='rotate-phone-image rotate-phone' />
      <div className='rotate-phone-bg' style={overContainerSize}>
        <div className='country-page-main-container' style={containerSize}>
          <div className='map-image map'></div>
          <div className='grid1-image grid__1'></div>
          <div className='grid2-image grid__2'></div>
          <div className='grid3-image grid__3'></div>
          <div className='grid4-image grid__4'></div>
          <div className='grid5-image grid__5'></div>
          <div className='grid6-image grid__6'></div>
          <div className='grid7-image grid__7'></div>
          <div className='grid8-image grid__8'></div>


          {/* {console.log('-------')}
          {console.log(photoIndex)}
          {console.log((photoIndex + 1) % images.length)}
          {console.log((photoIndex + images.length - 1) % images.length)}
          {console.log(images[(photoIndex + 1) % images.length])}
          {console.log(images[(photoIndex + images.length - 1) % images.length])} */}


          {showModal && (
            <Lightbox
              mainSrc={images[photoIndex]}
              nextSrc={images[(photoIndex + 1) % images.length]}
              prevSrc={images[(photoIndex + images.length - 1) % images.length]}

              onImageLoad={() => {
                window.dispatchEvent(new Event('resize'));
              }}
              onCloseRequest={() => setShowModal(false)}
              onMovePrevRequest={() =>
                setPhotoIndex(
                  (photoIndex + images.length - 1) % images.length
                )
              }
              onMoveNextRequest={() =>
                setPhotoIndex(
                  (photoIndex + images.length + 1) % images.length
                )
              }
            />
          )}

        </div>
        <div id='country-page-main-content' className='country-page-main-content' style={contentSize}>
          <DelayLink
            to={`/`}
            delay={1000}
            onDelayStart={() => {
              pageHide()
            }}
            onDelayEnd={() => {
            }}
          >
            <div id='btn-back-area' className='btn-back-area pe-none'></div>
          </DelayLink>
          <div id='page-bg' className='page-bg hided user-select-none'>
            <div className='title'>{currentCountryObj.title}</div>
            <div className='flag-container'>
              <img className="flag" src={flag_image} alt={currentCountryObj.title} />
            </div>
            <div className='list'>
              <ul style={variativeUlStyle}>
                {currentCountryObj.organizations.map((item, index) => (
                  <li key={index} style={variativeLiStyle}>
                    {item}
                  </li>
                ))}
              </ul>
            </div>
            <div className='line' />
            <img className="photo-main" src={images[0]} alt='' onClick={() => openModalAndSetIndex(0)} />
            <div className='main-text' dangerouslySetInnerHTML={{
              __html: main_text
            }} />
            <DelayLink
              to={`/`}
              delay={1000}
              onDelayStart={() => {
                pageHide()
              }}
              onDelayEnd={() => {
              }}
            >
              <div className='btn-back-image btn-back'></div>
            </DelayLink>
          </div>

        </div>
      </div>
    </div>
  )
}
