import React from 'react';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import MainPage from './components/pages/MainPage';
import CountryPage from './components/pages/CountryPage';
import LanguageWrapper from './components/components/LanguageWrapper';
import PageUnderwayWorks from './components/pages/PageUnderwayWorks';

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    // errorElement: <Page404/>,
    children: [
      {
        path: "/",
        element: <MainPage />,
      },
      {
        path: "pages/:id",
        element: <CountryPage />,
      },
    ]
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <LanguageWrapper>
      <RouterProvider router={router} />
      {/* <PageUnderwayWorks /> */}
    </LanguageWrapper>
  </React.StrictMode>
);
